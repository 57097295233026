import React from 'react'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import Search from './SearchResult'
import { callbackify } from 'util'
import Noty from "./Noty"
import NotificationModal from './Popups/NotificationModal'

const navigation = [
    // { name: 'Home', href: '/', current: true },
    // { name: 'Player', href: '/player', current: false },
    // { name: 'Seller', href: '/seller', current: false },
]
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Navbar( {auth, setWalletModal, setConnectStatus, onLoggedOut} ) {
    var currentUrl = window.location.pathname
    const [inputText, setInputText] = useState("")
    const [selected, setSelected] = useState("")
    const [expanded, setExpanded] = useState(false)
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = typeof e.target.value == "string" ? e.target.value.toLowerCase() : console.log("this fucking idiot var is not a string")
        console.log(lowerCase)
        setInputText(lowerCase)
    }
    const expand = () => {
        console.log("expand")
        setExpanded(true)
    }
    const close = () => {
        console.log("close")
        setExpanded(false)
    }
    const onClickHome = () => {
        window.location.href = "/"
    }
    // const onClickWallet = () => {
    //     window.location.href = "/wallet"
    // }
    const disconnectWallet = async () => {
        try {
            onLoggedOut();
            setConnectStatus(0);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Disclosure as="nav" className="bg-white dark:bg-gray-800 sticky top-0 z-50 min-w-[390px]">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="flex items-center justify-center sm:items-stretch sm:justify-start pl-3 sm:pl-0">
                                <button className="flex-shrink-0 flex items-center pt-0.5" onClick={onClickHome}>
                                    {/* <img
                                        className="block lg:hidden h-8 w-auto"
                                        src="/images/logo.png"
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src="/images/logo.png"
                                        alt="Workflow"
                                    />
                                    <p className='pl-4 align-middle text-2xl text-gray-800 dark:text-gray-200 font-bold pb-0.5'>6uo Games</p> */}
                                    <img
                                        className="block lg:hidden h-12 w-auto"
                                        src="/Logo/6uo Gameplace Beta (500 × 400 px).svg"
                                        alt="6uo_Games_logo"
                                    />
                                    <img
                                        className="hidden lg:block h-12 w-auto"
                                        src="/Logo/6uo Gameplace Beta (600 × 128 px).svg"
                                        alt="6uo_Games_logo"
                                    />
                                </button>
                            </div>
                        {/* Search Bar */}
                            <div className='hidden sm:block sm:w-5/12 px-1 relative'>
                                <form className='items-center'>   
                                    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                                    <div className="relative">
                                        <Search input={inputText} />
                                    </div>
                                </form>
                            </div>
                            
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {!expanded && (<button className='block sm:hidden p-1' onClick={expand}>
                                    <img src="/images/search.png" className="h-6 w-6 hover:scale-110 dark:hidden" alt="Search" />
                                    <img src="/images/search-white.png" className="h-6 w-6 hover:scale-110 hidden dark:block" alt="Search" />
                                </button>)}
                            {/* notifications */}
                                {
                                    auth && (<NotificationModal/>)
                                }
                            {/* Wallet */}
                                {!auth && (
                                    <button
                                    type="button"
                                    className="ml-3 bg-white dark:bg-gray-800 p-1 rounded-lg text-gray-500 hover:text-gray-700 hover:dark:text-gray-300 dark:hover:ring-gray-700 hover:scale-110"
                                    onClick={() => {setWalletModal(true)}}
                                    >
                                        <span className="sr-only">Your Wallet</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="26" height="26" fill='currentColor'><path d="M21,6H5c-.859,0-1.672-.372-2.235-.999,.55-.614,1.349-1.001,2.235-1.001H23c.553,0,1-.448,1-1s-.447-1-1-1H5C2.239,2,0,4.239,0,7v10c0,2.761,2.239,5,5,5H21c1.657,0,3-1.343,3-3V9c0-1.657-1.343-3-3-3Zm1,13c0,.551-.448,1-1,1H5c-1.654,0-3-1.346-3-3V6.998c.854,.639,1.904,1.002,3,1.002H21c.552,0,1,.449,1,1v10Zm-2-5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"/></svg>

                                        {/* <img src='/images/wallet.png' className="h-6 w-6 dark:hidden" aria-hidden="true" />
                                        <img src='/images/wallet-white.png' className="h-6 w-6 hidden dark:block" aria-hidden="true" /> */}
                                    </button>
                                )}
                            {/* Profile dropdown */}
                                {auth && (<Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className=" bg-white dark:bg-gray-800 p-1 rounded-lg text-gray-500 hover:text-gray-700 hover:dark:text-gray-300 dark:hover:ring-gray-700 hover:scale-110">
                                            <span className="sr-only">Open user menu</span>
                                            
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="26"
                                                height="26"
                                                fill={"currentColor"}
                                            >
                                                <g 
                                                    id="_01_align_center"
                                                    data-name="01 align center"
                                                >
                                                    <path
                                                        d="M21,24H19V18.957A2.96,2.96,0,0,0,16.043,16H7.957A2.96,2.96,0,0,0,5,18.957V24H3V18.957A4.963,4.963,0,0,1,7.957,14h8.086A4.963,4.963,0,0,1,21,18.957Z"
                                                    />
                                                    <path 
                                                        d="M12,12a6,6,0,1,1,6-6A6.006,6.006,0,0,1,12,12ZM12,2a4,4,0,1,0,4,4A4,4,0,0,0,12,2Z"
                                                    />
                                                </g>
                                            </svg>
                                            
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/profile"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Your collections
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/profile?tab=ActiveListings"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Active Listings
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/profile?tab=Friends"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Friends
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/profile?tab=Favorite"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Favorite
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/profile?tab=YourNFTs"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        YourNFTs
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Settings
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        onClick={disconnectWallet}
                                                        className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>)}
                            </div>
                        </div>
                    </div>
                    {expanded && (
                        <div className='flex justify-center px-1 text-left' onBlur={close}>
                            <form className='items-center w-8/12'>   
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                                <div className="relative">
                                    <Search input={inputText} autoFocus/>
                                </div>
                            </form>
                        </div>
                    )}
                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className={classNames(
                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'block px-3 py-2 rounded-md text-base font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default Navbar