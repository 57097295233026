import { React, useState , useEffect } from 'react'
import minterContract from '../contracts/Minter.json'
import gameplaceContract from '../contracts/GamePlace.json'
import { gameplaceAddress, polygonProvider } from '../config'
import { ethers } from 'ethers'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Select, { components } from 'react-select'
import { useNavigate } from 'react-router-dom'

const CaretDown = () => {
    return (
        <img src="/images/search.png" className='w-5 h-5' alt="Search" />
    )
}

const DropdownIndicator = ( props ) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDown />
        </components.DropdownIndicator>
    )
}

function SearchResult(props) {
    let navigate = useNavigate()
    const [nfts, setNfts] = useState([])
    const [loadingState, setLoadingState] = useState('not-loaded')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isMobile, setIsMobile] = useState(false)

    const loadNFTs = async () => {
        setIsLoading(true)

        // console.log('loadNFTs in searchresult is loading')

        const provider = new ethers.providers.JsonRpcProvider(polygonProvider)
        const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, provider)
        const data = await gameplacecontract.finderGame();

        const items = await Promise.all(data.map(async i => {
            const mintercontract = new ethers.Contract(i.mintContract, minterContract.abi, provider);
            const tokenUri = await mintercontract.uri(i.tokenId)
            const meta = await axios.get(tokenUri)
            let lowestPrice = ethers.utils.formatEther(i.lowestPrice.toString())
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
            value: meta.data.name.toString(),
            label: meta.data.name.toString(),
            // description: meta.data.description,
            // image: meta.data.image,
            // tokenUri: tokenUri,
            tokenId: i.tokenId.toNumber().toString(),
            // lowestPrice: lowestPrice,
            // price: price,
            }
            return item
        }))

        const games = items.sort((b, a) => a.tokenId - b.tokenId)
        // console.log('games', games)
        setNfts(games)
        setLoadingState('loaded')
        setIsLoading(false)
    }

    //create a new array by filtering the original array
    const filteredData = nfts.filter((el) => {
        // console.log('runnig filtereData')
        //if no input the return the original
        if (props.input === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            // if (el.name.toLowerCase().includes(props.input)) {
            //     console.log('Namessss: ', el.name.toLowerCase().includes(props.input))
                return el.value.toLowerCase().includes(props.input)
            // } else { 
            //     console.log('else return no game found')
            //     return (
            //         <div className='w-full absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            //             <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
            //                 No Games Found
            //             </ul>
            //         </div>
            //     )
        }
    })

    const onChange = (selectedOption) => {
        // setSelectedOption(selectedOption)
        navigate('/game/' + selectedOption.tokenId)
        console.log('onChange', selectedOption)
        navigate(0)
        // setSelectedOption(null)
    }

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 540) {
            setIsMobile(true)
            // console.log('isMobile', isMobile)
        } else {
            setIsMobile(false)
            // console.log('isMobile', isMobile)
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            color: '#374151',
            backgroundColor: '#f3f4f6',
            boxShadow: state.isFocused ? null : null,
            borderColor: state.isFocused ? '#15B8A6' : 'white',
            '&:hover': {
                borderColor: state.isFocused ? '#15B8A6' : 'white',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            position: 'relative',
            backgroundColor: state.isSelected ? '#15B8A6' : '#f3f4f6',
            color: state.isSelected ? '#f3f4f6' : '#374151',
            '&:hover': {
                backgroundColor: '#15B8A6',
                color: '#f3f4f6',
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            borderColor: '#15B8A6',
            backgroundColor: '#f3f4f6',
            color: '#374151',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#374151',
            borderColor: '#374151',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            color: '#374151',
        }),
    }
            


    useEffect(() => {
        loadNFTs()
        handleResize()
    }, [])

    if (loadingState === 'loaded' && !filteredData.length) return (
        <div className='w-full absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
                <li>
                    <p className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>No Games Found</p>
                </li>
            </ul>
        </div>
    )

    return (
        // props.input && 
        // (<div className='absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
        //     <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
        //         {filteredData.map((item) => (
        //             <li key={item.id}>
        //                 <Link to={`/game/${item.tokenId}`} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>{item.name}</Link>
        //             </li>
        //         ))}
        //     </ul>
        //     { console.log('filteredData: ', filteredData) }
        // </div>)  
        window.innerWidth < 640 ? (
            <Select
                styles={customStyles}
                autoFocus={true}
                components={{ DropdownIndicator }}
                placeholder='Search for a game'
                value={selectedOption}
                onChange={onChange}
                options={nfts} 
            />
        ) : (
            <Select
                styles={customStyles}
                components={{ DropdownIndicator }}
                placeholder='Search for a game'
                value={selectedOption}
                onChange={onChange}
                options={nfts} 
            />
        )
    )
}

export default SearchResult;

