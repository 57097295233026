import React from 'react'

function Intro() {
  return (
    <section className="max-w-screen-2xl px-4 py-5 pt-16 md:px-8 mx-auto">
        <div className="flex flex-wrap justify-between mb-8 md:mb-16">
            <div className="w-full lg:w-1/3 flex flex-col justify-center lg:pt-48 lg:pb-24 mb-6 sm:mb-12 lg:mb-0">
                <h1 className="text-black-800 dark:text-gray-100 text-4xl sm:text-5xl md:text-6xl font-bold mb-4 md:mb-8">Web 3 Community<br />for gamers</h1>
                <p className="max-w-md text-gray-500 dark:text-gray-300 xl:text-lg leading-relaxed">Keep track of your games collection and discover new items from the gameplace for crypto collectibles and NFTs.</p>
            </div>
            <div className="w-full lg:w-2/3 flex mb-12 md:mb-16">
                <a href='/game/#' className="bg-gray-100 rounded-lg shadow-lg overflow-hidden relative z-10 top-12 md:top-16 left-12 md:left-16 -ml-12 lg:ml-0 h-full w-full aspect-square">
                    <img src="/images/taxi.png" loading="eager" alt="Game image" className="w-full h-full object-cover object-center" />
                </a>
                <a href='/game/1' className="bg-gray-100 rounded-lg shadow-lg overflow-hidden h-full w-full aspect-square">
                    <img src="/images/eevee10.png" loading="eager" alt="Game image" className="w-full h-full object-cover object-center" />
                </a>
            </div>
        </div>
    </section>
  )
}

export default Intro