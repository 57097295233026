import React from 'react'
import axios from 'axios'
import minterContract from '../../contracts/Minter.json'
import gameplaceContract from '../../contracts/GamePlace.json'
import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { Link } from 'react-router-dom'

import { gameplaceAddress, polygonProvider } from '../../config'
import SkeletonNewestGames from '../SkeletonLoading/SkeletonNewestGames'

export default function NewestGames() {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [isLoading, setIsLoading] = useState(false)
  const scroller = React.createRef()
  var currentUrl = window.location.pathname;

  const handleRightScroll = () => {
    scroller.current.scrollLeft += 250
  }

  const handleLeftScroll = () => {
    scroller.current.scrollLeft -= 250
  }


  const loadNFTs = async () => {
    // console.log("currentUrl", currentUrl)
    setIsLoading(true)
    const provider = new ethers.providers.JsonRpcProvider(polygonProvider)
    const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, provider)
    
    const result = await provider.send('eth_chainId', []);
    console.log(result)
    const data = await gameplacecontract.finderGame()

    const items = await Promise.all(data.map(async i => {
      const mintercontract = new ethers.Contract(i.mintContract, minterContract.abi, provider)
      const tokenUri = await mintercontract.uri(i.tokenId)
      // console.log('tokenUri: ', i, tokenUri)
      const meta = await axios.get(tokenUri)
      // console.log('image: ', meta.data.image)
      const image = meta.data.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
      let lowestPrice = ethers.utils.formatEther(i.lowestPrice.toString())
      let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
      let item = {
        name: meta.data.name,
        description: meta.data.description,
        image: image,
        tokenId: i.tokenId.toNumber(),
        lowestPrice: lowestPrice,
        price: price,
      }
      return item
    }))

    const games = items.sort((b, a) => a.tokenId - b.tokenId)

    // const etherscan = await axios.get(
    //   `https://api-rinkeby.etherscan.io/api?module=account&action=txlistinternal&address=0xA77b98D26584f52c4aD6d6b0d49e04C5059FFe88&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=JDV21R2YNQX3SZBZ1E17FEP4GQH2N894TT`
    // )
    // let apiScan = etherscan.data

    // console.log(apiScan)
    
    setNfts(games)
    setLoadingState('loaded')
    setIsLoading(false)
  }

  const token0 = (nft) => {
    if (nft.tokenId === 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    loadNFTs()
  }, [])

  if (loadingState === 'loaded' && !nfts.length) return (<h1 className="px-20 py-10 text-3xl justify-center min-h-screen">No items in marketplace</h1>)

  if (currentUrl === '/') return (
    <div className='max-w-screen-2xl px-4 md:px-8 mx-auto'>
      <div className="pt-6 sm:pt-12 mb-8 md:mb-12">
        <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">New Releases</h2>
      </div>
      <div className='relative flex items-center max-w-screen-2xl px-4 md:px-8 mx-auto'>
        <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 pr-5 hidden sm:block' onClick={handleLeftScroll} >
          <img src='/images/left-arrow.png' className='dark:hidden' />
          <img src='/images/left-arrow-white.png' className='hidden dark:block' />
        </button>
        <div
          ref={scroller}
          className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide py-5'
        >
          {isLoading ? (
            <SkeletonNewestGames />
          ) : (
            <>
              {nfts.slice(0, 10).map((nft, i) => (
                <Link key={i} to={`/game/${nft.tokenId}`} className="px-2">
                  <div className="group inline-block border dark:border-gray-700 dark:hover:bg-gray-700 shadow rounded-xl overflow-hidden h-128 w-64">
                    <div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
                      <img src={nft.image} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="Game Icon" />
                    </div>
                    <div className="p-4">
                      <p style={{ height: '64px' }} className="text-2xl font-semibold dark:text-white font-mono">{nft.name}</p>
                      <div style={{ height: '70px', overflow: 'hidden' }}>
                        <p className="text-base text-gray-400 dark:text-gray-300 font-mono whitespace-normal">{nft.description}</p>
                      </div>
                    </div>
                    <div style={{ height: '64px', overflow: 'hidden'}} className="group-hover:bg-teal-400 p-4 bg-teal-500">
                      {
                        nft.price == nft.lowestPrice ? (
                          <div className='flex items-start justify-start'>
                            <img src='/images/matic.png' className='h-6 flex flex-col pr-1' />
                            <p className="text-xl font-bold text-white font-mono">{nft.price} </p>
                            <div className='col-span-10' />
                          </div>
                        ) : (
                          <div className='flex items-center justify-between'>
                            <div className='flex items-start justify-start'>
                              <img src='/images/matic.png' className='h-6 flex flex-col pr-1' />
                              <p className="text-xl font-bold text-white inline-block font-mono">{nft.lowestPrice} </p>
                            </div>
                            <p className="text-sm font-bold text-white line-through font-mono pb-2">{nft.price} </p>
                          </div>
                        ) 
                      }
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
        <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 pl-5 hidden sm:block' onClick={handleRightScroll} >
          <img src='/images/right-arrow.png' className='dark:hidden' />
          <img src='/images/right-arrow-white.png' className='dark:block hidden' />
        </button>
      </div>
    </div>
  )

  return (
    <div className="flex justify-center min-h-max pb-16 pt-24 bg-white dark:bg-gray-800">
      <div className="px-4">
        <div className="mb-10 md:mb-16">
            <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">New Releases</h2>
        </div>
        {isLoading ? (
          <SkeletonNewestGames />
        ):(        
          <div className="grid grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6 xl:gap-8">
            {
              nfts.map((nft, i) => (
                <div key={i}>
                  { token0(nft) ? (
                    <div className="hidden"></div>
                  ) : (
                    <Link to={`/game/${nft.tokenId}`}>
                      <div className="group border dark:border-gray-700 dark:hover:bg-gray-700 shadow rounded-xl overflow-hidden h-128 w-64">
                        <div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
                          <img src={nft.image} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="Game Icon" />
                        </div>
                        <div className="p-4">
                          <p style={{ height: '64px' }} className="text-2xl font-semibold dark:text-white font-mono">{nft.name}</p>
                          <div style={{ height: '70px', overflow: 'hidden' }}>
                            <p className="text-base text-gray-400 dark:text-gray-300 font-mono">{nft.description}</p>
                          </div>
                        </div>
                        <div style={{ height: '64px', overflow: 'hidden'}} className="group-hover:bg-teal-400 p-4 bg-teal-500">
                          {
                            nft.price == nft.lowestPrice ? (
                              <div className='flex items-start justify-start'>
                                <img src='/images/matic.png' className='h-6 flex flex-col pr-1' />
                                <p className="text-xl font-bold text-white font-mono">{nft.price} </p>
                                <div className='col-span-10' />
                              </div>
                            ) : (
                              <div className='flex items-center justify-between'>
                                <div className='flex items-start justify-start'>
                                  <img src='/images/matic.png' className='h-6 flex flex-col pr-1' />
                                  <p className="text-xl font-bold text-white inline-block font-mono">{nft.lowestPrice} </p>
                                </div>
                                <p className="text-sm font-bold text-white line-through font-mono pb-2">{nft.price} </p>
                              </div>
                            ) 
                          }
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              ))
            }
          </div>
        )}
      </div>
    </div>
  )
}

