
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { polygonProvider } from "../../config";
const axios = require('axios')

// import SkeletonPlayer from "../SkeletonLoading/SkeletonPlayer";

export default function FavoriteList( { onChangedIcon } ) {
	const [ownerAccount, setOwnerAccount] = useState();
    const [authToken, setAuthToken] = useState();
	const [loadingState, setLoadingState] = useState("not-loaded");
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

    const [nftList, setNftList] = useState([]);

	const is0Ac = useRef(true);

    const getUserNFTs = () => {
        const url = `https://polygon-mainnet.g.alchemy.com/v2/MSZ39dLwf14ryiwT7qjzTUJu8QbZTlz-/getNFTs/?owner=${ownerAccount}`;

        const config = {
            method: 'get',
            url: url,
        };
        
        // Make the request and print the formatted response:
        axios(config)
            .then((response) => {
                setNftList(response['data'].ownedNfts);
                console.log(response['data']);
            })
            .catch(error => console.log('error', error));
    }

    const imageHandler = async (image) => {
        var nft;
        try {

        if (image.includes('ipfs:/')) {
            nft = image.replace('ipfs:/', 'https://ipfs.io/ipfs/');
        } else {
            nft = image;
        }
            let formData = new FormData();
            formData.append("imgfile",nft);
            formData.append("senderAccount",ownerAccount);
            formData.append("authToken",authToken);
            let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/iconupload`,formData);
            console.log('image: ', res.data);
            onChangedIcon();
        } catch (error) {
            console.log(error);
        }
    }

	useEffect(() => {
        var account;
        var authToken;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setOwnerAccount(account);
        setAuthToken(authToken);
    },[])

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getUserNFTs();
    },[ownerAccount])

	return (
		<div className="flex justify-center pb-4 bg-white dark:bg-gray-800">
			<div className="">
                <div className="grid xmd:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 pt-4">
                    {
                        nftList.map((nfts, i) => (
                            <div key={i} className="group border dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 shadow rounded-xl overflow-hidden h-128 w-64">
                                    <div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
                                        <img src={
                                            nfts.metadata.image.replace('ipfs:/', 'https://ipfs.io/ipfs/')} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="nft" />
                                    </div>
                                <div className="p-4">
                                    <p className="test-2xl font-semibold dark:text-gray-200">{nfts.metadata.name}</p>
                                    <button className="bg-gray-800 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-150 ease-in-out" onClick={() => imageHandler(nfts.metadata.image.replace('ipfs:/', 'https://ipfs.io/ipfs/'))}>use as icon</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
			</div>
		</div>
	)
}
