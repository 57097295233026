import React from 'react'
import { useEffect , useState } from "react"
import { gameplaceAddress, polygonProvider } from "../../config.js"
import mintercontract from '../../contracts/Minter.json'
import gameplacecontract from '../../contracts/GamePlace.json';
import { ethers } from 'ethers'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SkeletonTopGames from '../SkeletonLoading/SkeletonTopGames.js';

// var tokenId = 1
const chain = "goerli"

const TopGames = () => {
    const [nfts, setNfts] = useState([])
    const [loadingState, setLoadingState] = useState('not-loaded')
    const [isLoading, setIsLoading] = useState(false)
    const arg = [];
    var currentUrl = window.location.pathname;

    const loadNFTs = async () => {
        setIsLoading(true)

        var items;

        try {
            fetch( `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/topsellers`, {
                timeout: 6000
            })
            .then( (res) => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch data')
                }
                return res.json();
            })
            .then((data) => {
                items = data.list;
                setNfts(items)
                setLoadingState('loaded')
                setIsLoading(false)
            })
            .catch( async (e) => {
                items = [];
                setNfts(items)
                setLoadingState('loaded')
                setIsLoading(false)
            });
        } catch (e) {
            console.log(e)
        };
        
    }

    useEffect(() => {
        loadNFTs()
    } , [])
    
    if (loadingState === 'loaded' && !nfts.length) return (<h1 className="px-20 py-10 text-3xl justify-center min-h-screen">No items in marketplace</h1>)
  
    if (isLoading == true && currentUrl !== '/') return (
      <div className="flex h-screen justify-center items-center bg-white dark:bg-gray-800">
        <svg role="status" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
      </div>
    )

    if (currentUrl === '/') return (
        <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                {/* text - start */}
                <div className="mb-10 md:mb-16">
                    <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Top Sellers</h2>
                    {/* <p className="max-w-screen-md text-gray-500 dark:text-gray-300 md:text-lg text-center mx-auto">Games that most people buy and play. We recommend you take a look at it.</p> */}
                </div>
                {/* text - end */}
                {/* image - start */}
                {isLoading ? (
                    <SkeletonTopGames />
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
                        
                        {
                            nfts.slice(0, 4).map((nft, i) => (
                                <div key={i}>
                                    <a href={`/game/${nft.tokenId}`} className="group h-48 md:h-96 flex justify-end items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative">
                                        <img src={nft.image} loading="lazy" alt="Game Icon" className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200" />
                                        <div className="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

                                        <span className="inline-block text-gray-200 text-xs md:text-sm border border-gray-500 rounded-lg backdrop-blur relative px-2 md:px-3 py-1 mr-3 mb-3">{nft.name}</span>
                                    </a>
                                </div>
                            ))
                        }
                        
                    </div>
                )}
                {/* image - end */}
                <div className="flex justify-end items-end gap-8 pt-5">
                    <a href="/topgames" className="inline-block bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-600 active:bg-gray-200 dark:active:bg-gray-700 focus-visible:ring ring-indigo-300 border dark:border-gray-700 text-gray-500 dark:text-gray-500 dark:hover:text-gray-300 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 md:px-8 py-2 md:py-3">More</a>
                </div>
            </div>
        </div>
    )

    return (
        <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                {/* text - start */}
                <div className="mb-10 md:mb-16">
                    <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Top Sellers</h2>
                    {/* <p className="max-w-screen-md text-gray-500 dark:text-gray-300 md:text-lg text-center mx-auto">Games that most people buy and play. We recommend you take a look at it.</p> */}
                </div>
                {/* text - end */}
                <div className="grid grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3 xl:grid-cols-4 gap-4">
                    {/* image - start */}
                    {
                        nfts.map((nft, i) => (
                            <div key={i}>
                                <Link to={`/game/${nft.tokenId}`} >
                                    <div className="border dark:border-gray-700 shadow rounded-xl overflow-hidden h-128 w-64 hover:scale-105 mx-auto">
                                        <div className='w-64 h-64'>
                                            <img src={nft.image} className='w-full h-full object-cover' alt="Game Icon" />
                                        </div>
                                        <div className="p-4">
                                            <p style={{ height: '64px' }} className="text-2xl font-semibold dark:text-white font-mono">{nft.name}</p>
                                            <div style={{ height: '70px', overflow: 'hidden' }}>
                                            <p className="text-base text-gray-400 dark:text-gray-300 font-mono">{nft.description}</p>
                                            </div>
                                        </div>
                                        <div className="p-4 bg-teal-500">
                                            {
                                                nft.price == nft.lowestPrice ? (
                                                    <p className="text-2xl font-bold text-white font-mono">{nft.price} ETH</p>
                                                ) : (
                                                    <div className='grid grid-flow-col gap-4'>
                                                        <p className="text-xl font-bold text-white inline-block font-mono">{nft.lowestPrice} ETH</p>
                                                        <p className="text-sm font-bold text-white line-through font-mono">{nft.price} ETH</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                    {/* image - end */}
                </div>
            </div>
        </div>
    )
}

export default TopGames;
