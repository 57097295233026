import React , { useState , useEffect } from 'react';
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import './index.css';
import Gaming from './Pages/Gaming';
import Footer from './components/Footer';
import Game from './Pages/Game';
import LibraryContext from './LibraryContext'
import Navbar from './components/Navbar';
import Home from './Pages/Home';
import NotFound from './components/NotFound';

import { web3Modal } from "./connect/Wallet";
import { ethers } from 'ethers'
import TopGames from './components/Home/TopGames';
import NewestGames from './components/Home/NewestGames';
import WrongChain from './components/WrongChain';
import WalletModal from './components/Popups/WalletModal';
import Profile from './Pages/Profile';
import SomethingWrong from './Pages/SomethingWrong';
import HelpCenter from './HelpCenter/HelpHome';
import OtherAddress from './Pages/OtherAddress';
import Notification from './Pages/Notification';
import Settings from './Pages/Settings';


function App( props ) {
    
    // session token && expiry date
    const [expirydate, setExpirydate] = useState();
    const [authToken, setAuthToken] = useState();
    const [account, setAccount] = useState();
    const [chainId, setChainId] = useState();

    // web3 provider
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState(null);

    // status
    const [agreement, setAgreement] = useState(1);
    const [walletModal, setWalletModal] = useState(false);
    const [connectStatus, setConnectStatus] = useState(0);
    const [error, setError] = useState();

    const handleLoggedIn = (auth, account, expired_at, provider) => {
        localStorage.setItem("authToken", JSON.stringify(auth));
        localStorage.setItem("account", JSON.stringify(account));
        localStorage.setItem("expiryDate", JSON.stringify(expired_at));
        localStorage.setItem("provider", provider);
        setAuthToken( auth );
        setExpirydate( expired_at );
        setProvider( provider );
    };

    const handleLoggedOut = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("expiryDate");
        localStorage.removeItem("account");
        localStorage.removeItem("provider");
        setAuthToken( undefined );
        setExpirydate( undefined );
        disconnectWallet();
    };

    const disconnectWallet = async () => {
        await web3Modal.clearCachedProvider();
        window.localStorage.clear();
        setProvider(null);
        setLibrary(null);
    }

    const handleSignMessage = async (data) => {
        const message = `I am signing my login nonce: ${JSON.parse(data).nonce}`;

        try{
            const provider = await web3Modal.connect();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const account = accounts[0];
            if (accounts) {
                setAccount(accounts[0]);
            }
            await library.provider.request({
                method: "personal_sign",
                params: [message, account]
            }).then( (data) => {
    
                fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/login`,{
                    body: JSON.stringify({ address: `${account}`, signature: `${data}` }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                }).then ( (response) => {
                    return response.json();
                } ).then ( ( data ) => {
                    handleLoggedIn(data.session, account, data.expired_at, provider);
                } )
    
            });
        }catch (e){
            console.log( e );
        }
        
    }

    const agreeToTerms = async (data) => {
        console.log("data", data);
        try {
            if (JSON.parse(data).sign_agreement != 0) {
                console.log("sign agreement" + JSON.parse(data).sign_agreement);
                handleSignMessage(data);
                setAgreement(JSON.parse(data).sign_agreement);
            } else {
                console.log("no agreement");
                setAgreement(JSON.parse(data).sign_agreement);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const acceptedByUser = async () => {
        try {
            const accounts = await library.listAccounts();
            // fetch signup message / nonce from backend
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/user`,{
                body: JSON.stringify({ address: `${accounts[0]}` }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then ( (response) => {
                return response.text();
            }).then( (data) => {
                handleSignMessage(data);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const loginToMetaMask = async () => {

        try {
            const provider = await web3Modal.connectTo("injected");
            await provider.enable();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            setChainId(network.chainId);

            // fetch signup message / nonce from backend
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/user`,{
                body: JSON.stringify({ address: `${accounts[0]}` }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then ( (response) => {
                return response.text();
            } )
            .then( (data) => {
                agreeToTerms(data);
            });

        } catch (error) {
            console.log(error);
        }

        return (
            <div>loginning</div>
        )

    }

    const loginToCoinbase = async () => {

        try {
            const provider = await web3Modal.connectTo("walletlink");
            await provider.enable();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) 
                setAccount(accounts[0]);
            setChainId(network.chainId);

            // fetch signup message / nonce from backend
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/user`,{
                body: JSON.stringify({ address: `${accounts[0]}` }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then ( (response) => {
                return response.text();
            } )
            .then( (data) => {
                console.log('f data', data);
                handleSignMessage(data);
            });

        } catch (error) {
            
        }

    }

    const loginToWalletConnect = async () => {

        try {
            const provider = await web3Modal.connectTo("walletconnect");
            await provider.enable();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) 
                setAccount(accounts[0]);
            setChainId(network.chainId);

            // fetch signup message / nonce from backend
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/user`,{
                body: JSON.stringify({ address: `${accounts[0]}` }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then ( (response) => {
                return response.text();
            } )
            .then( (data) => {
                console.log('f data', data);
                handleSignMessage(data);
            });
        } catch (error) {

        }

    }

    const disconnect = async () => {
        try {
            handleLoggedOut();
        } catch (error) {
            console.log(error);
        }
    }

    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        console.log(lowerCase);
        setInputText(lowerCase);
    };

    const switchNetwork = async () => {
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x89" }]
            });
            const network = await library.getNetwork();
            setChainId(network.chainId);
        } catch (error) {
          console.log(error);
        }
    };

    // check & access session token in local storage
    useEffect(() => {

        try {
            // Access token is stored in local storage
            const ls = localStorage.getItem("authToken");
            const auth = JSON.parse(ls);
            const date = JSON.parse( localStorage.getItem("expirydate") );
            setAuthToken( auth );
            setExpirydate( date );
        } catch (e) {
            console.log(e);
            setError(e);
        }
    }, []);

    useEffect(() => {
        async function checkIfLoggedIn() {
            const provider = await web3Modal.connect();
            await provider.enable();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setAccount(accounts[0]);
            setChainId(network.chainId);
            setLibrary(library);
        }
        if (authToken) {
            checkIfLoggedIn();
        }
    }, [ authToken ]);

    useEffect(() => {
        if (provider?.on) {
          const handleChainChanged = (_hexChainId) => {
            console.log("chainChanged", _hexChainId);
            setChainId(_hexChainId);
          };
    
          const handleDisconnect = () => {
            console.log("disconnect");
            handleLoggedOut();
          };

          provider.on("chainChanged", handleChainChanged);
          provider.on("disconnect", handleDisconnect);
    
          return () => {
            if (provider.removeListener) {
              provider.removeListener("chainChanged", handleChainChanged);
              provider.removeListener("disconnect", handleDisconnect);
            }
          };
        }
      }, [provider]);

        const queryString = window.location.search;
        // console.log(queryString);

    return (
        <div className='static dark:bg-gray-800'>
            <BrowserRouter>
                <Navbar auth={authToken} setWalletModal={setWalletModal} setConnectStatus={setConnectStatus} onLoggedOut={handleLoggedOut} />
                {
                    chainId && chainId != 137 && (<WrongChain switchNetwork={switchNetwork}/>)
                }
                 <LibraryContext.Provider value={[library, setLibrary]}>
                    {walletModal && (<WalletModal connectStatus={connectStatus} setConnectStatus={setConnectStatus} setWalletModal={setWalletModal} auth={authToken} library={library} agreement={agreement} onSignInTo6uo={acceptedByUser} onLoggedInToMetaMask={loginToMetaMask} onLoggendInToCoinbase={loginToCoinbase} onLoggedInToWalletConnect={loginToWalletConnect} onLoggedOut={handleLoggedOut} />)}
                    {error && (<SomethingWrong error={error} setError={setError} onLoggedOut={handleLoggedOut} setWalletModal={setWalletModal} />)}
                    <Routes>
                        <Route path="/" element={<Home auth={authToken}/>} />
                        <Route path="/topgames" element={<TopGames/>} />
                        <Route path="/newestgames" element={<NewestGames/>} />
                        <Route path="/profile" element={<Profile auth={authToken} account={account}/>} />
                        <Route path="/settings" element={<Settings auth={authToken} account={account}/>} />
                        <Route path=':account' element={<OtherAddress />} />
                        <Route path='/notification' element={<Notification auth={authToken} />} />
                        <Route path="/help-center/*" element={<HelpCenter />} />
                        <Route path='/gaming' element={<Gaming/>}>
                            <Route path=':game_id' element={<Gaming/> } />
                        </Route>
                        <Route path='/game' element={<Game auth={authToken} setWalletModal={setWalletModal} />}>
                            <Route path=':tokenId' element={<Game auth={authToken}/>} />
                        </Route>
                        {/* <Route path='/test' element={<Test />} />
                        <Route path='/test2' element={<Test2 />} /> */}
                        <Route path='something_went_wrong' element={<SomethingWrong/>} />
                        <Route path="*" element={<NotFound/>} />
                    </Routes>
                </LibraryContext.Provider>
                <Footer/>
            </BrowserRouter>
        </div>
    )

}

export default App;